import * as React from 'react'
import './index.scss'
import { Button, Tabs, Toast } from "antd-mobile";
import { CaseList } from "../CaseList";
import { WorkOrderList } from "../WorkOrderList";

export function Home() {
  function report() {
    location.href = '/create-case';
  }

  return (
    <div>
      <Tabs>
        <Tabs.Tab title='我的Case' key='case'>
          <Button block color='primary' size='large' onClick={()=>report()}>
            我要报修
          </Button>
          <CaseList/>
        </Tabs.Tab>
        <Tabs.Tab title='我的工单' key='workorder'>
          <WorkOrderList/>
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}
