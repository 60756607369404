import * as React from 'react';
import { useState } from 'react';
import './index.scss';
import { Nav } from '../../components/Nav';
import { Button, Form, Input, Mask, Radio, TextArea, Toast } from 'antd-mobile';
import { BarCodeScanner } from '../../components/BarCodeScanner';
import { MediaRecorder } from '../../components/MediaRecorder';
import { CaseSymptomCode, CaseType, ICaseDeviceInfo } from '../../interface';
import { CaseDeviceInfoMock } from '../../mock';
import { createCaseByC } from '../../apis/case';
import { IReqCreateCaseByC, IReqCreateCaseByE } from '../../apis/interface';
import { useLocation } from 'react-router-dom';
import MediaPlayer from 'react-player';
import { DeleteOutline, EyeOutline } from "antd-mobile-icons";

export function CreateCase() {
  const _location = useLocation();
  const search = new URLSearchParams(_location.search);
  const createBy: 'custom' | 'engineer' =
    search.get('role') === 'engineer' ? 'engineer' : 'custom';

  const initialForm: Partial<IReqCreateCaseByC | IReqCreateCaseByE> = {
    caseType: CaseType.MAINTAIN,
  };
  const [deviceInfo, setDeviceInfo] = useState<ICaseDeviceInfo | undefined>();
  const [formInstance] = Form.useForm<IReqCreateCaseByC>();

  function onScanDone(serialNo: string) {
    Toast.show(`扫描成功：${serialNo}`);
    formInstance.setFieldValue('serialNo', serialNo);
    setDeviceInfo(CaseDeviceInfoMock);
  }

  function onRecordVideo(video: Blob) {
    // Toast.show('视频录制成功！');
    formInstance.setFieldValue('video', video);
    setRefresh((r) => !r);
  }

  function onRecordAudio(audio: Blob) {
    // Toast.show('录音录制成功！');
    formInstance.setFieldValue('audio', audio);
    setRefresh((r) => !r);
  }

  function handleSave() {
    Toast.show('保存成功！');
    // location.href = '/case-list';
  }

  async function handleSubmit() {
    try {
      await formInstance.validateFields();
      const data = formInstance.getFieldsValue();
      await createCaseByC(data);
      Toast.show('提交成功！');
      location.href = '/';
    } catch (e) {
      Toast.show('创建失败！');
    }
  }

  const [showPlayer, setShowPlayer] = useState(false);
  const [mediaSource, setMediaSource] = useState('');
  const [refresh, setRefresh] = useState(false);

  return (
    <div className="repair-report">
      <Nav title="报修提报" mode="embed" />
      <Mask visible={showPlayer} opacity="thick">
        <MediaPlayer
          url={mediaSource}
          playsinline
          controls
          width="100vw"
          height="100vh"
        />
      </Mask>
      <Form
        form={formInstance}
        initialValues={initialForm}
        layout="horizontal"
        footer={
          <>
            <Button
              block
              size="large"
              style={{ marginBottom: 8 }}
              onClick={handleSave}
            >
              保存
            </Button>
            <Button
              block
              type="submit"
              color="primary"
              size="large"
              onClick={handleSubmit}
            >
              提交
            </Button>
          </>
        }
      >
        <Form.Item
          name="serialNo"
          label="序列号"
          extra={<BarCodeScanner onScanDone={onScanDone} />}
          rules={[{ required: true, message: '序列号不能为空' }]}
        >
          <Input readOnly placeholder="请扫描条形码获取" />
        </Form.Item>
        <Form.Item label="归属客户">
          <Input
            disabled
            value={deviceInfo?.customerName}
            placeholder="扫描条形码自动获取"
          />
        </Form.Item>
        <Form.Item label="客户地址">
          <Input
            disabled
            value={deviceInfo?.customerAddress}
            placeholder="扫描条形码自动获取"
          />
        </Form.Item>
        <Form.Item label="仪器名称">
          <Input
            disabled
            value={deviceInfo?.name}
            placeholder="扫描条形码自动获取"
          />
        </Form.Item>
        {createBy === 'engineer' && (
          <>
            <Form.Item
              name="caseType"
              label="Case类型"
              rules={[{ required: true, message: 'Case类型不能为空' }]}
            >
              <Radio.Group>
                <Radio value={CaseType.MAINTAIN} style={{ marginRight: '1em' }}>
                  维修
                </Radio>
                <Radio value={CaseType.INSTALL} style={{ marginRight: '1em' }}>
                  装机
                </Radio>
                <Radio value={CaseType.DISPLACE} style={{ marginRight: '1em' }}>
                  置换
                </Radio>
                <Radio value={CaseType.OTHER}>其他</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="errorCode"
              label="症状代码"
              rules={[{ required: true, message: '症状代码不能为空' }]}
            >
              <Radio.Group>
                <Radio value={CaseSymptomCode.C3_Q_159}>
                  {CaseSymptomCode.C3_Q_159}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}

        <Form.Item
          name="desc"
          label="问题描述"
          rules={[{ required: true, message: '问题描述不能为空' }]}
        >
          <TextArea
            placeholder="请输入问题描述，不超过100字"
            autoSize={{ minRows: 3, maxRows: 5 }}
            maxLength={100}
          />
        </Form.Item>
        <Form.Item name="video" label="补充视频" extra={
          <MediaRecorder type="video" onChange={onRecordVideo} />
        }>
          {formInstance.getFieldValue('video') ? (
            <>
              <EyeOutline
                fontSize={24}
                style={{margin: '0 8px'}}
                onClick={() => {
                  setMediaSource(
                    URL.createObjectURL(formInstance.getFieldValue('video'))
                  );
                  setShowPlayer(true);
                }}
              />
              <DeleteOutline
                color="#ff3141"
                fontSize={24}
                onClick={() => {
                  formInstance.setFieldValue('video', null);
                  setRefresh((r) => !r);
                }}
              />
            </>
          ): <span style={{color: '#eee'}}>点击右侧图标开始录制</span>}
        </Form.Item>
        <Form.Item name="audio" label="补充音频" extra={
          <MediaRecorder type="audio" onChange={onRecordAudio} />
        }>
          {formInstance.getFieldValue('audio') ? (
            <>
              <EyeOutline
                fontSize={24}
                style={{margin: '0 8px'}}
                onClick={() => {
                  setMediaSource(
                    URL.createObjectURL(formInstance.getFieldValue('audio'))
                  );
                  setShowPlayer(true);
                }}
              />
              <DeleteOutline
                color="#ff3141"
                fontSize={24}
                onClick={() => {
                  formInstance.setFieldValue('audio', null);
                  setRefresh((r) => !r);
                }}
              />
            </>
          ): <span style={{color: '#eee'}}>点击右侧图标开始录制</span>}
        </Form.Item>
        <Form.Item hidden>
          <div
            style={{
              visibility: refresh ? 'hidden' : 'visible',
              display: !refresh ? 'none' : 'block',
            }}
          ></div>
        </Form.Item>
      </Form>
    </div>
  );
}
