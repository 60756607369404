import * as React from 'react';
import { NavBar } from 'antd-mobile';
import { useEffect } from 'react';
import { updatePageTitle } from '../../utils/nav';

export interface INavAction {
  visible?: boolean;
  label?: string;
  icon?: React.ReactNode;
  action?: () => void;
}

interface Props {
  back?: INavAction;
  title?: string;
  right?: INavAction;
  mode?: 'normal'|'embed'
}

export function Nav(props: Props) {
  const {
    back = {
      visible: true,
      label: '',
      icon: '',
      action: undefined
    },
    title = '',
    right,
    mode = 'normal'
  } = props;

  useEffect(()=>{
    if (mode === 'embed') {
      updatePageTitle(title || '塞比亚报修系统')
    }
  }, [title])

  if (mode === 'embed') {
    return <></>
  }

  function renderRight(props: INavAction):React.ReactNode {
    const {
      visible = false,
      label = '',
      icon = '',
      action,
    } = props;
    if (!visible) {
      return <></>;
    }
    return <div className='action' onClick={action}>
      {icon}
      {label}
    </div>;
  }

  return (
    <NavBar className='nav' back={back.visible ?? true ? back.label : ''} onBack={back.action} backArrow={back.visible ?? true ? back.icon : null}
            right={renderRight(right ?? {})}>
      {title}
    </NavBar>
  );
}
