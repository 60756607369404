import axios from "axios";

const BASE_URL = '/api/'
const TIMEOUT = 30*1000

const request = axios.create({
  baseURL:BASE_URL,
  timeout:TIMEOUT
})

//请求拦截
request.interceptors.request.use(config => {
  // TODO 添加token
  return config
},error => {
  console.error('请求失败', error)
})

//响应拦截
request.interceptors.response.use(res => {
  // TODO 处理响应码
  return res.data
},error => {
  // TODO 页面报错
  return error;
})

export default request
