import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import './index.scss';
import { getQuery } from './utils/url';
import { setToken } from './utils/session';
import route from './route';
const router = createBrowserRouter(route);

function init() {
  const token = getQuery('token');
  if (!token) {
    // 未获取token的情况下跳转授权页面获取openid
    // return
  }
  setToken(getQuery('token'));

  ReactDOM.createRoot(document.getElementById('root') ?? document.body).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>,
  );
}

init()
