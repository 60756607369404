import React from 'react';
import { IAction, ICaseListItem } from '../../interface';
import { Collapse } from 'antd-mobile';

interface IProps extends Partial<ICaseListItem> {
  key: string;
  onView: IAction<string>;
  onCreateWorkOrder: IAction<string>;
}

export function CaseListItem(props: IProps) {
  const {
    key,
    caseNo = '1234435123',
    reportTime = '2023/5/20 18:22:30',
    deviceName = '血糖分析仪',
    status = 0,
    onView,
    onCreateWorkOrder,
  } = props;

  return (
    <Collapse.Panel key={key} className='case-list-item' title={
      <div className='header'>
        <div className='no'>序号：{caseNo}</div>
        <div className='time'>{reportTime}</div>
        <div className='action-label'>查看详情</div>
      </div>
    }
    >
      <div className='content'>
        <div className='info'>
          <div>设备名称：{deviceName}</div>
          <div>处理结果：{status}</div>
        </div>
        <div className='actions' onClick={(e) => onView(caseNo)}>查看</div>
        <div className='actions' onClick={(e) => onCreateWorkOrder(caseNo)}>创建工单</div>
      </div>
    </Collapse.Panel>
  );
}
