import * as React from 'react';
import { useRef, useState } from 'react';
import { AudioOutline, LoopOutline, VideoOutline } from 'antd-mobile-icons';
import RecordRTC from 'recordrtc';
import { Button, Mask } from 'antd-mobile';
import './index.scss';
import { callCamera, closeStream } from '../../utils/rtc';

interface Props {
  type?: 'audio' | 'video';
  onChange?: (value?: Blob) => void;
}

export function MediaRecorder(props: Props) {
  const { type = 'video', onChange = () => undefined } = props;

  async function handleOnClick() {
    openRecorder();
    openMedia();
  }

  const [videoRecorderOpen, setVideoRecorderOpen] = useState(false);
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment');
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoStreamRef = useRef<MediaStream>(null);

  const [audioRecorderOpen, setAudioRecorderOpen] = useState(false);
  const audioStreamRef = useRef<MediaStream>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  function changeFacingMode() {
    const newFacingMode = facingMode === 'environment' ? 'user' : 'environment';
    setFacingMode(newFacingMode);
    callCamera({
      width: { ideal: 640 },
      height: { ideal: 320 }, facingMode: newFacingMode,
    }).then(stream => {
      videoRef.current.srcObject = stream;
      videoStreamRef.current = stream;
    });
  }

  function openRecorder() {
    type === 'video' && setVideoRecorderOpen(true);
    type === 'audio' && setAudioRecorderOpen(true);
    window.addEventListener('popstate', closeMedia);
  }

  function closeRecorder() {
    window.removeEventListener('popstate', closeMedia);
    setRecording(false);
    setPreviewMode(false);
    closeMedia();
  }

  async function openMedia() {
    if (type === 'video') {
      try {
        const stream = await callCamera({
          width: { ideal: 640 },
          height: { ideal: 320 }, facingMode,
        });
        videoRef.current && (videoRef.current.src = '');
        videoRef.current && (videoRef.current.srcObject = stream);
        videoStreamRef.current = stream;
        return videoRef.current.play()
      } catch (e) {
        console.error('调用相机失败：', e);
      }
    } else {
      try {
        audioStreamRef.current = await callMicrophone();
        audioRef.current && (audioRef.current.src = '');
      } catch (e) {
        console.error('调用麦克风失败：', e);
      }
    }
    recorderRef.current?.reset();
  }

  function closeMedia() {
    setVideoRecorderOpen(false);
    setAudioRecorderOpen(false);
    closeStream(videoStreamRef.current);
    closeStream(audioStreamRef.current);
    videoRef.current && (videoRef.current.src = '');
    videoRef.current && (videoRef.current.srcObject = null);
    audioRef.current && (audioRef.current.src = '');
    recorderRef.current?.destroy();
    recorderRef.current = null;
  }

  async function callMicrophone() {
    return navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });
  }

  const recorderRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [autoEnd, setAutoEnd] = useState(-1)

  function startRecord() {
    setRecording(true);
    if (type === 'video') {
      if (!videoStreamRef.current) {
        return;
      }
      const recorder = RecordRTC(videoStreamRef.current, {
        type: 'video',
        mimeType: 'video/mp4',
      });
      recorder.startRecording();
      recorderRef.current = recorder;

      // 如果到指定时间还未结束录制，则自动结束录制
      const timout = setTimeout(() => {
        endRecord();
      }, 60 * 1000);
      setAutoEnd(Number(timout))
    } else {
      if (!audioStreamRef.current) {
        return;
      }
      const recorder = RecordRTC(audioStreamRef.current, {
        type: 'audio',
        mimeType: '  audio/webm',
      });
      recorder.startRecording();
      recorderRef.current = recorder;
    }
  }

  function endRecord() {
    setRecording(false);
    if (autoEnd > 0) {
      clearTimeout(autoEnd)
      setAutoEnd(-1)
    }
    if (recorderRef.current.state === 'recording') {
      recorderRef.current.stopRecording(function() {
        const source = recorderRef.current.toURL() as string;
        preview(source);
      });
    }
  }

  function onFinish() {
    onChange(recorderRef.current.getBlob());
    closeRecorder();
  }

  function onCancel() {
    if (previewMode) {
      setPreviewMode(false);
      openMedia();
    } else {
      closeRecorder();
    }
  }

  const [previewMode, setPreviewMode] = useState(false);

  function preview(source: string) {
    setPreviewMode(true);
    if (type === 'audio') {
      closeStream(audioStreamRef.current);
      audioRef.current.src = source;
      // audioRef.current.muted = false;
      audioRef.current.play();
    } else {
      closeStream(videoStreamRef.current);
      videoRef.current.srcObject = null;
      videoRef.current.src = source;
      // videoRef.current.muted = false;
      videoRef.current.play();
    }
  }

  return (
    <span onClick={handleOnClick}>
      {type === 'audio' ? (
        <AudioOutline fontSize={24} />
      ) : (
        <VideoOutline fontSize={24} />
      )}
      <Mask visible={videoRecorderOpen}>
        <div className='video-recorder'>
          <video ref={videoRef} autoPlay muted controls={false} loop={previewMode} playsInline/>
          <div className='bottom-action-panel'>
            <div className='left' onClick={onCancel}
                 style={{ visibility: recording ? 'hidden' : 'visible' }}>{previewMode ? '重拍' : '取消'}
            </div>
            {recording ? <div className='end-btn' onClick={endRecord} /> :
              <>
                <div className='start-btn' onClick={startRecord} />
              </>}
            <div className='right' style={{ visibility: recording ? 'hidden' : 'visible' }}>
              {
                previewMode ? <Button size='small' color='success' onClick={onFinish}>完成</Button> :
                  <LoopOutline fontSize={24} onClick={changeFacingMode} />
              }
            </div>
          </div>
        </div>
      </Mask>
      <Mask visible={audioRecorderOpen}>
        <div className='audio-recorder'>
          <audio ref={audioRef} autoPlay controls={false} loop={previewMode} />
          <div className='bottom-action-panel'>
            <div className='left' onClick={onCancel}
                 style={{ visibility: recording ? 'hidden' : 'visible' }}>{previewMode ? '重录' : '取消'}
            </div>
            {recording ? <div className='end-btn' onClick={endRecord} /> :
              <>
                <div className='start-btn' onClick={startRecord} />
              </>}
            <div className='right' style={{ visibility: recording ? 'hidden' : 'visible' }}>
              {
                previewMode && <Button size='small' color='success' onClick={onFinish}>完成</Button>
              }
            </div>
          </div>
        </div>
      </Mask>
    </span>
  );
}
