import request from "./request";
import {
  IResCaseListItem,
  IReqCaseList,
  IReqCreateCaseByC,
  IReqGetDeviceInfo,
  IResGetDeviceInfoByC,
  IResGetCaseDetailByC, IReqGetCaseDetail, IResGetCaseDetailByE, IReqModifyCase
} from "./interface";

// 通过序列号获取设备信息
export function getDeviceInfo(params: IReqGetDeviceInfo) {
  return request.post<IResGetDeviceInfoByC>('/device/selectBySerialNo', params)
}

// 客户创建Case
export function createCaseByC(params: IReqCreateCaseByC) {
  return request.postForm('/caseInfo/createCase', params)
}

// 获取Case列表
export function getCaseList(params?: IReqCaseList) {
  return request.post<IResCaseListItem[]>('/caseInfo/caseList', params)
}

// 客户查看Case详情
export function getCaseDetailByC(params: IReqGetCaseDetail) {
    return request.post<IResGetCaseDetailByC>('/caseInfo/viewCase', params)
}

// 工程师查看Case详情
export function getCaseDetailByE(params: IReqGetCaseDetail) {
    return request.post<IResGetCaseDetailByE>('/caseInfo/viewCaseByEngineer', params)
}

// 工程师编辑case单，首次编辑提交后即成功接单
export function modifyCase(params: IReqModifyCase) {
  return request.post('/caseInfo/viewCaseByEngineer', params)
}
