import * as React from 'react';
import { Home } from './pages/Home';
import { CreateWorkOrder } from './pages/CreateWorkOrder';
import { CaseDetail } from './pages/CaseDetail';
import { WorkOrderDetail } from './pages/WorkOrderDetail';
import { CreateCase } from './pages/CreateCase';
import { Report } from './pages/Report';

export default [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/create-case',
    element: <CreateCase />,
  },
  {
    path: '/case-detail',
    element: <CaseDetail />,
  },
  {
    path: '/create-work-order',
    element: <CreateWorkOrder />,
  },
  {
    path: '/work-order-detail',
    element: <WorkOrderDetail />,
  },
  {
    path: '/report',
    element: <Report />,
  },
];
