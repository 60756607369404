declare global{
  interface Window {
    token:string
  }
}

export type IAction<P> = (params: P) => void;

export enum HandleResult {
  SUCCESS,
  END,
}

// TODO 待完善
export enum CaseType {
  // 装机
  INSTALL = 'INSTALL',
  // 维修
  MAINTAIN = 'MAINTAIN',
  // 置换
  DISPLACE = 'DISPLACE',
  // 其他
  OTHER = 'OTHER'
}

export interface ICaseDeviceInfo {
  // 归属客户
  customerName: string;
  // 归属客户地址
  customerAddress: string;
  // 归属客户电话
  customerPhone: string;
  // 仪器名称
  name: string;
  // 仪器状态
  status: string;
  // 仪器装机时间
  installTime: string;
  // 工程师信息
  engineer?: IEngineer;
}

export enum CaseSymptomCode {
  C3_Q_159 = 'C3_Q-159-阴极槽'
}

// 工单状态
export enum CaseStatus{
  // 待处理
  INITIAL ,
  PROCESSING,
  FINISHED
}

export interface ICaseListItem {
  // case编号
  caseNo: string;
  // case状态
  status: CaseStatus;
  // 客户姓名
  customerName: string;
  // 上报时间
  reportTime: string;
  // 设备名称
  deviceName: string;
  // 工单类型
  caseType: CaseType;
}

export interface ICaseDetail extends ICaseListItem{
  // 故障代码
  errorCode: string;
  // 问题描述
  descText: string;
  // 视频地址
  descVideo: string;
  // 录音地址
  descAudio: string;

  workOrderList: IWorkOrderListItem[]
}

export interface IWorkOderCaseInfo {
  no: string;
  caseType: string;
  customName: string;
  // 提交时间
  reportTime: string;
  customPhone: string;
  customAddress: string;
  errorCode: string;
  descText: string;
  descVideo?: string;
  descAudio?: string;
}

export interface IWorkOrderDeviceInfo {
  serialNo: string;
  deviceName: string;
  hardwareVersion: string;
  softwareVersion: string;
  status: string;
  installTime: string;
}

export interface IModule {
  name: string;
  count: number;
}

export interface IWorkHour {
  // 出发
  setOutTime: string
  // 工作开始时间
  workStartTime: string
  // 工作结束时间
  workEndTime: string
  // 返回
  returnTime: string
  totalWorkHours?: string;
  onWayWorkHours?: string;
  workHours?: string;
}
// 提交工单表单
export interface IWorkOrderSubmit {
  // case信息
  caseNo: string;
  serviceType: string;
  // 仪器编号
  serialNo: string;
  // 工时记录
  workHours: IWorkHour;
  modules: IModule[];
  isInMaintenance: '1' | '0';
  doa: '1' | '0';
}

export enum WorkOrderStatus{
  INITIAL,
  PROCESSING,
  FINISHED
}

export interface IWorkOrderListItem {
  // case编号
  caseNo: string;
  // 工单编号
  no: string;
  // 处理工程师
  engineer: string;
  // 工单状态
  status: WorkOrderStatus;
}

export interface IWorkOrderDetail {
  caseInfo: IWorkOderCaseInfo;
  deviceInfo: IWorkOrderDeviceInfo
  workHours: IWorkHour;
  modules: IModule[];
  isInMaintenance: string;
  doa: string;
  // 工单状态
  status: string;
}

export interface IEngineer {
  name: string;
  department: string;
  phone: string;
  type: string;
}

