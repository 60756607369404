export function closeStream(stream: MediaStream | null) {
  stream?.getTracks().forEach((track) => track.stop());
}

export  async function callCamera(config?: {
  width?: ConstrainULong,
  height?: ConstrainULong,
  facingMode?: 'user' | 'environment'
}, withAudio?: boolean) {
  return navigator.mediaDevices.getUserMedia({
    video: {
      width: config?.width ?? { ideal: 720 },
      height: config?.height ?? { ideal: 1280 },
      facingMode: config.facingMode ?? 'environment',
    },
    audio: withAudio ?? true,
  });
}
