import * as React from 'react';
import { useState } from 'react';
import './index.scss';
import { Nav } from '../../components/Nav';
import { Button, DatePicker, Dialog, Form, Input, Radio, Toast } from 'antd-mobile';
import { IModule, IWorkOderCaseInfo, IWorkOrderDeviceInfo } from '../../interface';
import { WorkOrderCaseInfoMock, WorkOrderDeviceInfoMock } from '../../mock';
import moment from 'moment';
import { CloseOutline } from 'antd-mobile-icons';

enum DateType {
  NONE,
  SET_OUT_TIME,
  WORK_START_TIME,
  WORK_END_TIME,
  RETURN_TIME,
}

export function CreateWorkOrder() {
  const [formInstance] = Form.useForm();
  const [caseInfo, setCaseInfo] = useState<IWorkOderCaseInfo | undefined>(
    WorkOrderCaseInfoMock,
  );
  const [deviceInfo, setDeviceInfo] = useState<
    IWorkOrderDeviceInfo | undefined
  >(WorkOrderDeviceInfoMock);
  const [showDatePicker, setShowDatePicker] = useState<DateType>(DateType.NONE);

  const initialForm = {
    isInMaintenance: '1',
    doa: '1',
    modules: [{ name: '', count: 0 }],
  };

  console.log(111, formInstance.getFieldValue('modules'));

  function handleSave() {
    Toast.show('保存成功！');
    location.href = '/work-order-list';
  }

  async function handleSubmit() {
    try {
      await formInstance.validateFields();
      Toast.show('提交成功！');
      location.href = '/work-order-list';
    } catch (e) {
    }
  }

  function viewCaseInfo(caseInfo?: IWorkOderCaseInfo) {
    if (!caseInfo) {
      return;
    }
    Dialog.show({
      header: 'Case信息',
      content: (
        <Form layout='horizontal'>
          <Form.Item label='case编号'>{caseInfo?.no}</Form.Item>
          <Form.Item label='case类型'>{caseInfo?.caseType}</Form.Item>
          <Form.Item label='客户名称'>{caseInfo?.customName}</Form.Item>
          <Form.Item label='客户电话'>{caseInfo?.customPhone}</Form.Item>
          <Form.Item label='客户地址'>{caseInfo?.customAddress}</Form.Item>
          <Form.Item label='上报时间'>{caseInfo?.reportTime}</Form.Item>
          <Form.Item label='症状代码'>{caseInfo?.errorCode}</Form.Item>
          <Form.Item label='问题描述'>{caseInfo?.descText}</Form.Item>
          {(caseInfo?.descVideo || caseInfo?.descAudio) && (
            <Form.Item label='附件'>
              {caseInfo?.descVideo && (
                <a
                  style={{ display: 'block' }}
                  href={caseInfo.descVideo}
                  target='_blank'
                >
                  查看视频
                </a>
              )}
              {caseInfo?.descAudio && (
                <a
                  style={{ display: 'block' }}
                  href={caseInfo.descAudio}
                  target='_blank'
                >
                  查看录音
                </a>
              )}
            </Form.Item>
          )}
        </Form>
      ),
      closeOnMaskClick: true,
    });
  }

  function viewDeviceInfo(deviceInfo?: IWorkOrderDeviceInfo) {
    if (!deviceInfo) {
      return;
    }
    Dialog.show({
      header: '仪器信息',
      content: (
        <Form layout='horizontal'>
          <Form.Item label='仪器编号'>{deviceInfo?.serialNo}</Form.Item>
          <Form.Item label='硬件版本'>{deviceInfo?.hardwareVersion}</Form.Item>
          <Form.Item label='软件版本'>{deviceInfo?.softwareVersion}</Form.Item>
          <Form.Item label='使用状态'>{deviceInfo?.status}</Form.Item>
          <Form.Item label='装机时间'>{deviceInfo?.installTime}</Form.Item>
        </Form>
      ),
      closeOnMaskClick: true,
    });
  }

  function viewDoaTemplate() {
    Toast.show('查看模板');
  }

  const [modules, setModules] = useState<IModule[]>([]);

  function updateModules(index: number, name: string, count: number) {
    // const modules = formInstance.getFieldValue('modules') ?? [];
    modules[index].name = name;
    modules[index].count = count;
    // formInstance.setFieldValue('modules', [...modules]);
    setModules([...modules]);
  }

  function addModule(name = '', count = 0) {
    // const modules = formInstance.getFieldValue('modules') ?? [];
    modules.push({ name, count });
    // formInstance.setFieldValue('modules', [...modules]);
    setModules([...modules]);
  }

  function delModule(index: number) {
    // const modules = formInstance.getFieldValue('modules') ?? [];
    modules.splice(index, 1);
    // formInstance.setFieldValue('modules', [...modules]);
    setModules([...modules]);
  }

  const [totalWorkHours, setTotalWorkHours] = useState(0);
  const [onWayWorkHours, setOnWayWorkHours] = useState(0);
  const [workHours, setWorkHours] = useState(0);

  return (
    <div>
      <Nav title='工单提报' mode='embed' />
      <Form
        layout='horizontal'
        form={formInstance}
        initialValues={initialForm}
        footer={
          <>
            <Button
              block
              size='large'
              style={{ marginBottom: 8 }}
              onClick={handleSave}
            >
              保存
            </Button>
            <Button
              block
              type='submit'
              color='primary'
              size='large'
              onClick={handleSubmit}
            >
              提交
            </Button>
          </>
        }
      >
        <Form.Header>Case信息</Form.Header>
        <Form.Item
          label='Case信息'
          extra={
            caseInfo && (
              <div
                className='action-label'
                onClick={() => viewCaseInfo(caseInfo)}
              >
                查看详情
              </div>
            )
          }
        >
          <Input disabled value={caseInfo?.no} />
        </Form.Item>

        <Form.Item
          label='仪器信息'
          extra={
            deviceInfo && (
              <div
                className='action-label'
                onClick={() => viewDeviceInfo(deviceInfo)}
              >
                查看详情
              </div>
            )
          }
        >
          <Input disabled value={deviceInfo?.serialNo} />
        </Form.Item>

        <Form.Item label='服务类型'>
          <Input disabled value={caseInfo?.caseType} />
        </Form.Item>

        <Form.Header>工时填报</Form.Header>
        <Form.Item label='出发时间'>
          <Input
            onClick={() => setShowDatePicker(DateType.SET_OUT_TIME)}
            value={formInstance?.getFieldValue('setOutTime')}
            placeholder='选择出发时间'
          />
        </Form.Item>
        <Form.Item label='工作开始时间'>
          <Input
            onClick={() => setShowDatePicker(DateType.WORK_START_TIME)}
            value={formInstance?.getFieldValue('workStartTime')}
            placeholder='选择工作开始时间'
          />
        </Form.Item>
        <Form.Item label='工作结束时间'>
          <Input
            onClick={() => setShowDatePicker(DateType.WORK_END_TIME)}
            value={formInstance?.getFieldValue('workEndTime')}
            placeholder='选择工作结束时间'
          />
        </Form.Item>
        <Form.Item label='返回时间'>
          <Input
            onClick={() => setShowDatePicker(DateType.RETURN_TIME)}
            value={formInstance?.getFieldValue('returnTime')}
            placeholder='选择返回时间'
          />
        </Form.Item>
        <Form.Item label='总时长'>
          {totalWorkHours}
        </Form.Item>
        <Form.Item label='路途时长'>
          {onWayWorkHours}
        </Form.Item>
        <Form.Item label='工作时长'>
          {workHours}
        </Form.Item>
        <DatePicker
          visible={showDatePicker !== DateType.NONE}
          onClose={() => {
            setShowDatePicker(DateType.NONE);
          }}
          precision='minute'
          onConfirm={(val) => {
            const time = moment(val).format('YYYY/MM/DD HH:mm');
            switch (showDatePicker) {
              case DateType.NONE:
                break;
              case DateType.SET_OUT_TIME:
                formInstance.setFieldValue('setOutTime', time);
                break;
              case DateType.WORK_START_TIME:
                formInstance.setFieldValue('workStartTime', time);
                break;
              case DateType.WORK_END_TIME:
                formInstance.setFieldValue('workEndTime', time);
                break;
              case DateType.RETURN_TIME:
                formInstance.setFieldValue('returnTime', time);
                break;
            }
          }}
        />

        <Form.Header>配件信息</Form.Header>
        <Form.Item
          label={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>配件添加</div>
              <div className='action-label' onClick={() => addModule()}>
                添加
              </div>
            </div>
          }
          layout='vertical'
        >
          {modules.map(
            (module: IModule, index: number) => (
              <div className='module-row' key={index}>
                <div className='name'>
                  <span className='label'>名称</span>
                  <Input
                    value={module.name}
                    onChange={(name) =>
                      updateModules(index, name, module.count)
                    }
                    placeholder='请输入名称'
                  />
                </div>
                <div className='count'>
                  <span className='label'>数量</span>
                  <Input
                    type='number'
                    value={String(module.count)}
                    onChange={(count) =>
                      updateModules(index, module.name, Number(count))
                    }
                    placeholder='请输入数量'
                  />
                </div>
                <CloseOutline
                  fontSize={24}
                  color='#ff3141'
                  onClick={() => {
                    Dialog.confirm({
                      title: '提示',
                      content: '是否确认删除？',
                      onConfirm() {
                        delModule(index);
                      },
                    });
                  }}
                />
              </div>
            ),
          )}
        </Form.Item>

        <Form.Header>补充信息</Form.Header>
        <Form.Item name='isInMaintenance' label='是否保修'>
          <Radio.Group>
            <Radio value='1' style={{ marginRight: '1em' }}>是</Radio>
            <Radio value='0'>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name='doa' label={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>是否生成配件索赔PDF</div>
            <div className='action-label' onClick={viewDoaTemplate}>查看模板</div>
          </div>
        } layout='vertical'>
          <Radio.Group>
            <Radio value='1' style={{ marginRight: '1em' }}>是</Radio>
            <Radio value='0'>否</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </div>
  );
}
