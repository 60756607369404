import {
  CaseStatus,
  CaseType,
  CaseSymptomCode,
  ICaseDetail,
  ICaseDeviceInfo,
  ICaseListItem,
  IWorkOderCaseInfo,
  IWorkOrderDetail,
  IWorkOrderDeviceInfo,
  WorkOrderStatus,
} from './interface';

export const CaseList: ICaseListItem[] = [];

export const CaseDeviceInfoMock: ICaseDeviceInfo = {
  customerAddress: '上海市浦东新区高教中心123号',
  customerName: '李四',
  customerPhone: '1234567789',
  engineer: {
    name: '张三',
    department: '维修一部',
    phone: '1234567',
    type: '维修',
  },
  installTime: '2022/3/5 12:30',
  name: '血糖分析仪',
  status: '保内',
};

export const CaseDetailMock:ICaseDetail = {
  caseNo: '888888888888',
  status: CaseStatus.PROCESSING,
  reportTime: '2022/3/5 12:30',
  caseType: CaseType.MAINTAIN,
  customerName: '李四',
  descAudio: 'https://xxxx.com/xxx.mp3',
  descText: '设备运行有异响',
  descVideo: 'https://xxxx.com/xxx.mp4',
  deviceName: '血糖分析仪',
  errorCode: 'C3_Q-159-阴极槽',
  workOrderList: [
    {
      caseNo: '888888888888',
      no: '66666666666',
      engineer: "张三",
      status: WorkOrderStatus.PROCESSING
    }
  ]
}

export const WorkOrderCaseInfoMock: IWorkOderCaseInfo = {
  reportTime: '2022/3/5 12:30',
  caseType: '维修',
  customAddress: '上海市浦东新区高教中心123号',
  customName: '李四',
  customPhone: '123454897',
  errorCode: CaseSymptomCode.C3_Q_159,
  no: '4563123456',
  descText: '阴极槽错位',
  descVideo: 'https://xxxx.com/xxx.mp4',
  descAudio: 'https://xxxx.com/xxx.mp3'
};

export const WorkOrderDeviceInfoMock: IWorkOrderDeviceInfo = {
  hardwareVersion: '2.0',
  deviceName: '血糖分析仪',
  installTime: '2022/3/5 12:30',
  serialNo: '4568781123',
  softwareVersion: '1.0',
  status: '使用中'
};

export const WorkOrderDetailMock :IWorkOrderDetail = {
  caseInfo: {
    no: "123456789456",
    caseType: "维修",
    customName: "李四",
    reportTime: "2022/3/5 12:30",
    customPhone: "1234567789",
    customAddress: "上海市浦东新区高教中心123号",
    errorCode: "C3_Q-159-阴极槽",
    descText: "设备故障",
    descVideo: "https://xxxx.com/xxx.mp4",
    descAudio: "https://xxxx.com/xxx.mp3",
  },
  deviceInfo: {
    hardwareVersion: '2.0',
    deviceName: '血糖分析仪',
    installTime: '2022/3/5 12:30',
    serialNo: '4568781123',
    softwareVersion: '1.0',
    status: '使用中'
  },
  doa: '是',
  isInMaintenance: '是',
  modules: [
    {name: '螺丝', count: 1},
    {name: '钉子', count: 2},
    {name: '半导体', count: 1},
  ],
  status: '处理中',
  workHours: {
    setOutTime: '2022/3/5 12:30',
    workStartTime: '2022/3/5 12:30',
    workEndTime: '2022/3/5 12:30',
    returnTime: '2022/3/5 12:30',
    totalWorkHours: '5小时',
    onWayWorkHours: '3小时20分',
    workHours: '1小时40分',
  }
}
