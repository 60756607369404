import React, { useLayoutEffect, useRef, useState } from 'react';
import { ScanningOutline } from 'antd-mobile-icons';
import { AutoCenter, Button, Mask, Space, Toast } from 'antd-mobile';
import './index.scss';
import Quagga from 'quagga';
import { callCamera, closeStream } from '../../utils/rtc';

interface Props {
  onScanDone: (val: string) => void;
  onScanCancel?: () => void;
}

export function BarCodeScanner(props: Props) {
  const { onScanDone = () => undefined, onScanCancel = () => undefined } =
    props;

  const [scanning, setScanning] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoStreamRef = useRef<MediaStream>(null);
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>(
    'environment'
  );

  useLayoutEffect(() => {
    if (!scanning) {
      if (videoStreamRef.current) {
        closeStream(videoStreamRef.current);
        Quagga.stop();
        videoStreamRef.current = null;
      }
      return;
    }

    callCamera(
      {
        facingMode,
      },
      false
    )
      .then((stream) => {
        if (!videoRef.current) {
          closeStream(stream);
          return;
        }

        videoStreamRef.current = stream;
        videoRef.current.srcObject = stream;
        videoRef.current.play().then(() => {
          startScan(stream);
        });
      })
      .catch((err) => {
        console.error(err);
        Toast.show('唤起相机失败，请检查相机是否被占用！');
      });
  }, [scanning]);

  function changeFacingMode() {
    const newFacingMode = facingMode === 'environment' ? 'user' : 'environment';
    setFacingMode(newFacingMode);
    callCamera({ facingMode: newFacingMode }, false).then((stream) => {
      videoStreamRef.current = stream;
      videoRef.current.srcObject = stream;
      return videoRef.current.play()
    });
  }

  function startScan(stream: MediaStream) {
    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          target: videoRef.current,
          type: 'LiveStream',
        },
        decoder: {
          readers: ['code_128_reader'],
        },
      },
      function (err?: any) {
        if (err) {
          console.error(err);
          Toast.show('唤起相机失败，请检查相机是否被占用！');
          return;
        }
        console.log('开始检测条形码');
        Quagga.start();
        Quagga.onDetected((data: any) => {
          console.log('检测中', data);
          if (data?.codeResult?.code) {
            console.log('检测到条形码', data.codeResult.code);
            onScanDone(data.codeResult.code);
            Quagga.stop();
            closeStream(stream);
            setScanning(false);
          }
        });
      }
    );
  }

  return (
    <div className="bar-code-scanner" onClick={() => setScanning(true)}>
      <ScanningOutline fontSize={24} />
      <Mask visible={scanning}>
        <AutoCenter>
          <Space direction="vertical">
            <video
              ref={videoRef}
              className="bar-scan-video"
              autoPlay
              muted
              playsInline
            />
            <Button
              block
              onClick={() => {
                changeFacingMode();
              }}
            >
              切换摄像头
            </Button>
            <Button
              block
              color="danger"
              onClick={() => {
                setScanning(false);
                onScanCancel();
              }}
            >
              取消
            </Button>
          </Space>
        </AutoCenter>
      </Mask>
    </div>
  );
}
