import request from "./request";
import { IReqInsertWorkOrder, IReqWorkOrderList, IResWorkOrderListItem } from "./interface";

// 获取工单列表
export function getWorkOrderList(params?: IReqWorkOrderList) {
  return request.post<IResWorkOrderListItem[]>('/workOrder/workOrderList', params)
}

// 创建或编辑工单
export function insertWorkOrder(params: IReqInsertWorkOrder) {
  return request.post('/workOrder/insertWorkOrder', params)
}
