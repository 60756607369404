import * as React from 'react';
import { useEffect, useState } from 'react';
import './index.scss';
import { ErrorBlock, Toast } from 'antd-mobile';
import { IWorkOrderListItem } from '../../interface';
import { getWorkOrderList } from "../../apis/order";

export function WorkOrderList() {
  const [caseList, setCaseList] = useState<IWorkOrderListItem[]>([]);

  useEffect(() => {
    getWorkOrderList().then(console.log);
  }, []);

  function onViewCase(caseId: string) {
    Toast.show(`查看Case，${caseId}`);
  }

  function onCreateWorkOrder(caseId: string) {
    Toast.show(`创建工单，CaseId：${caseId}`);
  }

  return (
    <div>
      {caseList.length === 0 && (
        <ErrorBlock
          status="empty"
          title={<div>找不到数据</div>}
          description={<div>暂时没有查询到数据，请重试</div>}
        />
      )}
      {/*{caseList.map(c => <WorkOrderListItem key={c.id} id={c.id} onView={onViewCase} onCreateWorkOrder={onCreateWorkOrder}*/}
      {/*                                      no={c.no}*/}
      {/*                                      reportTime={c.reportTime} deviceName={c.deviceName} result={c.result} />)}*/}
    </div>
  );
}
