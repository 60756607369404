import React, { useState } from 'react';
import { CaseDetailMock } from '../../mock';
import { CaseStatus, CaseType, ICaseDetail, WorkOrderStatus } from '../../interface';
import { Nav } from '../../components/Nav';
import './index.scss';
import { Card, Collapse, Dialog, List, Tag, Toast } from 'antd-mobile';
import { PlayOutline } from 'antd-mobile-icons';

export function CaseDetail() {
  const [caseDetail, setCaseDetail] = useState<ICaseDetail>(CaseDetailMock);

  function renderCaseStatus(status: CaseStatus) {
    switch (status) {
      case CaseStatus.INITIAL:
        return (
          <Tag fill="outline" round color="default">
            待处理
          </Tag>
        );
      case CaseStatus.PROCESSING:
        return (
          <Tag fill="outline" round color="primary">
            处理中
          </Tag>
        );
      case CaseStatus.FINISHED:
        return (
          <Tag fill="outline" round color="success">
            已完结
          </Tag>
        );
    }
  }

  function renderWorkOrderStatus(status: WorkOrderStatus) {
    console.log(111, status)
    switch (status) {
      case WorkOrderStatus.INITIAL:
        return (
          <Tag fill="outline" round color="default">
            草稿
          </Tag>
        );
      case WorkOrderStatus.PROCESSING:
        return (
          <Tag fill="outline" round color="primary">
            处理中
          </Tag>
        );
      case WorkOrderStatus.FINISHED:
        return (
          <Tag fill="outline" round color="success">
            已完结
          </Tag>
        );
    }
  }

  function renderType(caseType: CaseType) {
    switch (caseType) {
      case CaseType.INSTALL:
        return (
          <Tag fill="outline" round color="primary">
            装机
          </Tag>
        );
      case CaseType.MAINTAIN:
        return (
          <Tag fill="outline" round color="primary">
            维修
          </Tag>
        );
      case CaseType.DISPLACE:
        return (
          <Tag fill="outline" round color="primary">
            置换
          </Tag>
        );
      case CaseType.OTHER:
        return (
          <Tag fill="outline" round color="primary">
            其他
          </Tag>
        );
    }
  }

  function viewWorkOrder(workOrderNo: string) {
    location.href = '/work-order-detail'
  }

  return (
    <div className="case-detail">
      <Nav title="Case详情" mode="embed" />
      <div className="title">
        <span className="case-no">CASE编号：{caseDetail.caseNo}</span>
      </div>
      <div className="tags">
        <div style={{ marginRight: '2em' }}>
          状态：{renderCaseStatus(caseDetail.status)}
        </div>
        <div>类型：{renderType(caseDetail.caseType)}</div>
      </div>
      <Card className="report-info-card" title="上报信息">
        <div className="report-info-content">
          <div className="row">
            <div className="reporter">
              <div className="label">上报人</div>
              <div>{caseDetail.customerName}</div>
            </div>
            <div className="report-time">
              <div className="label">上报时间</div>
              <div>{caseDetail.reportTime}</div>
            </div>
          </div>
          <div className="row">
            <div className="device-name">
              <div className="label">仪器名称</div>
              <div>{caseDetail.deviceName}</div>
            </div>
            <div className="error-code">
              <div className="label">故障代码</div>
              <div>{caseDetail.errorCode}</div>
            </div>
          </div>
          <div className="row">
            <div className="desc">
              <div className="label">问题描述</div>
              <div>{caseDetail.descText}</div>
            </div>
          </div>
          <div className="row">
            <div className="video">
              <div className="label">查看视频</div>
              {caseDetail.descVideo ? (
                <div
                  className="video-player"
                  onClick={() =>
                    Dialog.show({
                      content: (
                        <div>
                          <video
                            style={{ width: '100%', objectFit: 'cover' }}
                            controls
                            src="https://static.alibabagroup.com/static/fb23a195-4822-4688-9809-db1609ae29c9.mp4"
                          ></video>
                        </div>
                      ),
                      closeOnMaskClick: true,
                    })
                  }
                >
                  <PlayOutline fontSize={24} color="white" />
                </div>
              ) : (
                <div>未提交</div>
              )}
            </div>
            <div className="audio">
              <div className="label">查看录音</div>
              {caseDetail.descAudio ? (
                <div
                  className="audio-player"
                  onClick={() =>
                    Dialog.show({
                      content: (
                        <div>
                          <audio
                            style={{ width: '100%', objectFit: 'cover' }}
                            controls
                            src="https://www.apple.com.cn/105/media/us/privacy/2019/58349417-857c-47c2-89c3-6ab1a56a2f58/safari/medium.mp4"
                          ></audio>
                        </div>
                      ),
                      closeOnMaskClick: true,
                    })
                  }
                >
                  <PlayOutline fontSize={24} color="white" />
                </div>
              ) : (
                <div>未提交</div>
              )}
            </div>
          </div>
        </div>
      </Card>
      <List header="工单列表">
        {
          caseDetail.workOrderList.map(workOrder=>(
            <List.Item description={`工程师：${workOrder.engineer}`} clickable onClick={()=>viewWorkOrder(workOrder.no)}>
              <div className="work-order-item">
                <span style={{marginRight: '1rem'}}>编号：{workOrder.no}</span>
                {renderWorkOrderStatus(workOrder.status)}
              </div>
            </List.Item>
          ))
        }
      </List>
    </div>
  );
}
