import * as React from 'react';
import { useEffect, useState } from "react";
import { CaseListItem } from './CaseListItem';
import './index.scss';
import { ErrorBlock, Toast } from 'antd-mobile';
import { ICaseListItem } from '../../interface';
import { getCaseList } from "../../apis/case";

export function CaseList() {
  const [caseList, setCaseList] = useState<ICaseListItem[]>([]);

  useEffect(()=>{
    getCaseList().then()
  }, [])

  function onViewCase(caseId: string) {
    Toast.show(`查看Case，${caseId}`);
  }

  function onCreateWorkOrder(caseId: string) {
    Toast.show(`创建工单，CaseId：${caseId}`);
  }

  return (
    <div>
      {caseList.length === 0 && (
        <ErrorBlock
          status="empty"
          title={<div>找不到数据</div>}
          description={<div>暂时没有查询到数据，请重试</div>}
        />
      )}
      {caseList.map((c) => (
        <CaseListItem
          key={c.caseNo}
          onView={onViewCase}
          onCreateWorkOrder={onCreateWorkOrder}
          caseNo={c.caseNo}
          reportTime={c.reportTime}
          deviceName={c.deviceName}
          status={c.status}
        />
      ))}
    </div>
  );
}
