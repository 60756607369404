import React, { useState } from 'react';
import { Nav } from '../../components/Nav';
import { Dialog, Form } from 'antd-mobile';
import { IModule, IWorkOderCaseInfo, IWorkOrderDetail, IWorkOrderDeviceInfo } from '../../interface';
import { WorkOrderDetailMock } from '../../mock';

export function WorkOrderDetail() {
  const [workOrderDetail, setWorkOrderDetail] = useState<
    IWorkOrderDetail | undefined
  >(WorkOrderDetailMock);

  function viewCaseInfo(caseInfo?: IWorkOderCaseInfo) {
    if (!caseInfo) {
      return;
    }
    Dialog.show({
      header: 'Case信息',
      content: (
        <Form layout="horizontal">
          <Form.Item label="case编号">{caseInfo?.no}</Form.Item>
          <Form.Item label="case类型">{caseInfo?.caseType}</Form.Item>
          <Form.Item label="客户名称">{caseInfo?.customName}</Form.Item>
          <Form.Item label="客户电话">{caseInfo?.customPhone}</Form.Item>
          <Form.Item label="客户地址">{caseInfo?.customAddress}</Form.Item>
          <Form.Item label="上报时间">{caseInfo?.reportTime}</Form.Item>
          <Form.Item label="症状代码">{caseInfo?.errorCode}</Form.Item>
          <Form.Item label="问题描述">{caseInfo?.descText}</Form.Item>
          {(caseInfo?.descVideo || caseInfo?.descAudio) && (
            <Form.Item label="附件">
              {caseInfo?.descVideo && (
                <a
                  style={{ display: 'block' }}
                  href={caseInfo.descVideo}
                  target="_blank"
                >
                  查看视频
                </a>
              )}
              {caseInfo?.descAudio && (
                <a
                  style={{ display: 'block' }}
                  href={caseInfo.descAudio}
                  target="_blank"
                >
                  查看录音
                </a>
              )}
            </Form.Item>
          )}
        </Form>
      ),
      closeOnMaskClick: true,
    });
  }

  function viewDeviceInfo(deviceInfo?: IWorkOrderDeviceInfo) {
    if (!deviceInfo) {
      return;
    }
    Dialog.show({
      header: '仪器信息',
      content: (
        <Form layout="horizontal">
          <Form.Item label="仪器编号">{deviceInfo?.serialNo}</Form.Item>
          <Form.Item label="硬件版本">{deviceInfo?.hardwareVersion}</Form.Item>
          <Form.Item label="软件版本">{deviceInfo?.softwareVersion}</Form.Item>
          <Form.Item label="使用状态">{deviceInfo?.status}</Form.Item>
          <Form.Item label="装机时间">{deviceInfo?.installTime}</Form.Item>
        </Form>
      ),
      closeOnMaskClick: true,
    });
  }

  return (
    <div>
      <Nav title="工单详情" mode="embed" />
      <Form layout="horizontal" initialValues={workOrderDetail}>
        <Form.Header>Case信息</Form.Header>
        <Form.Item
          label="Case信息"
          extra={
            workOrderDetail?.caseInfo && (
              <div
                className="action-label"
                onClick={() => viewCaseInfo(workOrderDetail?.caseInfo)}
              >
                查看详情
              </div>
            )
          }
        >
          {workOrderDetail?.caseInfo?.no}
        </Form.Item>

        <Form.Item
          label="仪器信息"
          extra={
            workOrderDetail?.deviceInfo && (
              <div
                className="action-label"
                onClick={() => viewDeviceInfo(workOrderDetail?.deviceInfo)}
              >
                查看详情
              </div>
            )
          }
        >
          {workOrderDetail?.deviceInfo?.serialNo}
        </Form.Item>

        <Form.Item label="服务类型">
          {workOrderDetail?.caseInfo?.caseType}
        </Form.Item>

        <Form.Header>工时填报</Form.Header>
        <Form.Item label="出发时间">
          {workOrderDetail?.workHours.setOutTime}
        </Form.Item>
        <Form.Item label="工作开始时间">
          {workOrderDetail?.workHours.workStartTime}
        </Form.Item>
        <Form.Item label="工作结束时间">
          {workOrderDetail?.workHours.workEndTime}
        </Form.Item>
        <Form.Item label="返回时间">
          {workOrderDetail?.workHours.returnTime}
        </Form.Item>
        <Form.Item label="总时长">
          {workOrderDetail?.workHours.totalWorkHours}
        </Form.Item>
        <Form.Item label="路途时长">
          {workOrderDetail?.workHours.onWayWorkHours}
        </Form.Item>
        <Form.Item label="工作时长">
          {workOrderDetail?.workHours.workHours}
        </Form.Item>
        <Form.Header>配件信息</Form.Header>
        <Form.Item label="配件添加">
          {workOrderDetail?.modules.map((module: IModule, index: number) => (
            <div className="module-row" key={index}>
              <div className="label">
                <span>{module.name}</span>
                <span>*{module.count}</span>
              </div>
            </div>
          ))}
        </Form.Item>

        <Form.Header>补充信息</Form.Header>
        <Form.Item label="是否保修">
          {workOrderDetail?.isInMaintenance}
        </Form.Item>
        <Form.Item label="是否生成配件索赔PDF">
          {workOrderDetail?.doa}
        </Form.Item>
        <Form.Item label="工单状态">
          {workOrderDetail?.status}
        </Form.Item>
      </Form>
    </div>
  );
}
